@import 'variables';

@font-face {
  font-family: 'Carton';
  src: url('../fonts/Carton_Six.ttf');
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-Light.ttf');
}

body {
  margin: 0;
  background-color: $blue-color;

  font-family: 'SourceSansPro';

  a {
    text-decoration: underline;
    color: $yellow-color;
  }
}
