.container {
  display: flex;
  flex-direction: column;
  color: white;
  width: 660px;
  margin: 0 auto;

  img {
    max-width: 384px;
  }

  button {
    margin: 16px;
  }
}

.radioButtons {
  display: block;
}
